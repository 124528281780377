import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

const Layout = () => {
  const { authToken } = useContext(AuthContext);
  if (!authToken) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Topbar />
      <div className="flex-grow">
        <div className="container my-10">
          <div className="flex gap-10">
            <div className="w-2/12 hidden lg:inline-block">
              <Sidebar />
            </div>
            <div className="w-10/12">
              <div className="drawer lg:drawer-open">
                <input
                  id="my-drawer-2"
                  type="checkbox"
                  className="drawer-toggle"
                />
                <div className="drawer-content pb-20 px-2 lg:px-0 lg:py-0">
                  <Outlet />
                </div>
                <div className="drawer-side">
                  <label
                    htmlFor="my-drawer-2"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                  ></label>
                  <div className="lg:hidden p-4">
                    <Sidebar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
